<template>
  <div class="fullscreen white-skin">
    <mdb-view class="intro-2 fullscreen-view" >
      <mdb-mask overlay="stylish-slight" style="height: 100%; overflow: scroll;">
        <mdb-container class=" justify-content-center align-items-center">
          <mdb-row class="mt-3 pt-3 justify-content-md-center" >
            <mdb-col md="6">
              <mdb-card class="mb-5">
                <mdb-card-body>
                  <h3
                    class="font-weight-bold my-4 text-center mb-5 mt-4 font-weight-bold">
                    <strong>Administration</strong>
                  </h3>
                  <hr />
                  <mdb-row class="mt-5">
                    <mdb-col md="12">
                      <mdb-alert color="danger" v-if="errormsg">
                        {{errormsg}}
                      </mdb-alert>
                      <mdb-input label="Adresse courriel" type="text" class="text-white" v-model="username" @keyup.enter.native="checkforenterpressed"/>
                      <mdb-input label="Mot de passe" type="password" v-model="password" @keyup.enter.native="checkforenterpressed"/>

                      <div class="text-center">
                        <mdb-btn color="elegant" rounded class="mb-3" v-on:click="login">Connexion</mdb-btn>
                      </div>
                    </mdb-col>
                  </mdb-row>
                </mdb-card-body>
              </mdb-card>
            </mdb-col>
          </mdb-row>
        </mdb-container>
      </mdb-mask>
    </mdb-view>
  </div>
</template>

<script>
    import {
        mdbBtn,
        mdbInput,
        mdbView,
        mdbMask,
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbCardBody,
        mdbAlert,
    } from 'mdbvue';

    import Auth from '../libs/Auth';

    export default {
        name: 'FormsPage',
        data() {
            return {
                username: '',
                password: '',
                errormsg: '',
            };
        },
        components: {
            mdbBtn,
            mdbInput,
            mdbView,
            mdbMask,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbCard,
            mdbCardBody,
            mdbAlert,
        },
        methods: {
            checkforenterpressed(e) {
                if (e.keyCode === 13) {
                    this.login();
                }
            },
            login() {
                const auth = new Auth();
                auth.login(this.username, this.password).then((result) => {
                    this.errormsg = '';
                    if (result.status === 'OK') {
                        this.$router.push({ path: '/adm/dashboard' });
                    } else {
                        this.errormsg = result.msg;
                        this.password = '';
                    }
                });
            },
        },
    };
</script>

<style scoped>
  .fullscreen {
    background-image: linear-gradient(90deg, #0d0b2f 0%, #a4c8f5 100%);
  }

  .fullscreen-view{
    height:100vh;
  }

  .top-nav-collapse {
    background-color: #3f51b5 !important;
  }

  .navbar:not(.top-nav-collapse) {
    background: transparent !important;
  }

  @media (max-width: 768px) {
    .navbar:not(.top-nav-collapse) {
      background: #3f51b5 !important;
    }
  }
  @media (min-width: 800px) and (max-width: 850px) {
    .navbar:not(.top-nav-collapse) {
      background: #3f51b5 !important;
    }
  }

  .rgba-gradient {
    background: -webkit-linear-gradient(
      180deg,
      rgba(13, 11, 47, 1),
      rgba(164,200,245,1) 100%
    );
    background: -webkit-gradient(
      linear,
      180deg,
      rgba(13, 11, 47, 1),
      rgba(164,200,245,1) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(13, 11, 47, 1),
      rgba(164,200,245,1) 100%
    );
  }

  .card {
    background-color: rgba(255, 255, 255, 0.85);
  }

  h6 {
    line-height: 1.7;
  }
</style>
